import {
  createSharedComposable,
  useStorage,
  StorageSerializers,
} from "@vueuse/core";

function _useCookiePolicy() {
  const showPreferences = ref(false);

  const preferences = useStorage("cookie-preferences", null, undefined, {
    serializer: StorageSerializers.object,
  });

  const showBanner = computed(() => {
    return !showPreferences.value && !preferences.value;
  });

  const approve = () => {
    preferences.value = {
      functionalCookies: true,
    };
  };

  const reject = () => {
    preferences.value = {
      functionalCookies: false,
    };
  };

  const init = () => {};

  init();

  return {
    showBanner,
    showPreferences,
    preferences,
    init,
    approve,
    reject,
  };
}

export const useCookiePolicy = createSharedComposable(_useCookiePolicy);
